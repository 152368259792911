const LegalRenumerationOrder = () => {
  return (
    <>
      <div>
        <embed
          src="files/Legal-Practitioners-Renumeration-Order-2023.pdf"
          type="application/pdf"
          width="100%"
          height="1000px"
        />

        {/* </embed> */}
      </div>
    </>
  );
};

export default LegalRenumerationOrder;
